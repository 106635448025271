
body{
    font-family: $base-font;
    font-size: $base-font-size;
}
.modal-backdrop.show {
    opacity: .7;
}
.form-group{
    margin-bottom: 15px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #555555;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
  color: #fff;
}
// Buttons
.btn, .btn:focus, .btn:active, .btn:focus-within, .btn:active:focus{
    box-shadow: none;
    outline: none;
}
.btn-sm{

}
.btn-md{

}
.btn-lg{

}

.btn-primary, .btn-secondary, .btn-border-primary, .btn-outline{
    border-radius: 16px;
    padding: 12px 28px;
    font-size: $base-font-size;
    font-weight: 500;
}
.btn-border-primary{
    color: $primary-color;
    border-color: $primary-color;
    background-color: transparent;
    &:hover{
        color: #fff;
        border-color: $primary-color;
        background-color: $primary-color !important;    
    }
}
.btn-primary{
    &.btn-shadow{
        box-shadow: 4px 8px 50px rgba($primary-color,  .4);
    }
}

//Font Weight
.fw-500{
    font-weight: 500;
}

//Card
.ethsplitter{
    &__card{
        background: linear-gradient(94.84deg, rgba(26, 137, 224, 0.08) 0%, rgba(128, 199, 255, 0.02) 95.3%);    
        border-radius: 20px;
        .card-body{
            padding: 30px;
        }
        h5{
            margin-bottom: 14px;
        }
    }
}

//Tooltip
.tooltip{
    margin-left: 5px;
    background-color: transparent !important;
}
.tooltip.show {
    opacity: 1;
}
.tooltip-inner{
    backdrop-filter: blur(20px);
    text-align: left;
    color: #ddd;
    font-size: 13px;
    line-height: 1.6;
}
//float-icon
.float__shadow__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid;
    width: 86px;
    height: 86px;
    border-radius: 50%;
    z-index: 1;
    position: relative;
    &:before, &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        border-radius: 50%;
        transform: translate(-50%, -50%);
    }
    &:before{
        opacity: 0.45;
        filter: blur(50px);
        width: 108px;
        height: 108px;
    }
    &:after {
        border: 1px solid;
        opacity: .25;
        width: 116px;
        height: 116px;
    }
    img{
        max-width: 32px;
    }
}
//spacing 
@media (min-width: 768px ) {
    .our__values .card{
        min-height: 100%;
    }
}
@media (min-width: 992px ) {
    .ethsplitter__line-tabs{
        .nav-item{
            margin: 0 36px;
        }
    }
    //Heading 
    .common__heading{
        margin-bottom: 50px;
    }
    h1{
        font-size: 28px;
    }
    h2{
        font-size: 40px;
    }
    .burger{
        padding-top: 90px;
        padding-bottom: 90px;
    }
    .t-burger{
        padding-top: 90px;
    }
    .b-burger{
        padding-bottom: 90px;
    }
    .top__header-space{
        padding-top: 120px;
    }
}
//checkbox
.form-check-input[type="checkbox"], .form-check-input[type="checkbox"] + label{
    cursor: pointer;
}
.form-check-input{
    border-radius: 3px;
    width: 15px;
    height: 15px;
    box-shadow: none !important;
    background-color: transparent;
    border-color: #B8B8B8;
    &:checked {
        background-color: transparent;
        border-color: #B8B8B8;
        box-shadow: none;
    }
}


//form 
.icon__field {
    display: inline-block;
    width: 100%;
    position: relative;
    cursor: pointer;
    img {
        position: absolute;
        right: 14px;
        width: 20px;
        opacity: .4;
        top: 12px;
        &:hover{
            opacity: .7;
        }
    }
    &.percentage__field{
        &:before{
            content: "%";
            position: absolute;
            right: 14px;
            top: 8px;
            color: $primary-color;
            font-weight: 800;
            pointer-events: none;
        }
    }
}


//common Banner
.ethsplitter{
    &__commonBanner{
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        z-index: 1;
        &__content{
            h1{
                margin-bottom: 10px;
            }
            p{
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
    @media (min-width: 992px) {
        &__commonBanner{
            padding: 100px 0;
        }
        &__commonBanner__content{
            h1{
                font-size: 48px;
            }
        }
        &__section{
            &__content{
                &.right{
                    padding-left: 36px;
                }
                &.left{
                    padding-right: 36px;
                }
            }
        }
    }
    .ethsplitter__line-tabs{
        border: none;
        text-align: center;
        justify-content: center;
        margin-bottom: 50px;
        .nav-link{
            padding: 13px 2px;
            font-weight: 500;
            color: #fff;
            border: none;
            position: relative;
            &:before{
                content: "";
                width: 100%;
                height: 2px;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: $primary-color;
                transition: all ease .4s;
                transform: scale3d(0,1,1);
                border-radius: 10px;
                background-color: $primary-color;
            }
         /*&:after{
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                background: linear-gradient( 0deg, $primary-color 0%, rgba(26, 137, 224, 0.08) 0.01%, rgba(26, 137, 224, 0) 100%);
            }*/
            &.active, &:hover{
                &:before{
                    transform: scale3d(1,1,1);
                }
            }
            &.active{
                background: transparent ;
                color: $primary-color;
                border: none;
                font-weight: 500;
            }
        }
    }
    &__rounded-box{
        border: 1px solid;
        border-image-source: linear-gradient(
    180deg, rgba(159, 190, 251, 0.06) 0%, rgba(57, 125, 255, 0.06) 100%);
        background: radial-gradient(53.19% 53.19% at 50% 100%, rgba(110, 196, 255, 0.08) 0%, rgba(110, 196, 255, 0) 100%), linear-gradient(0deg, rgba(12, 17, 26, 0.4), rgba(12, 17, 26, 0.4));
        backdrop-filter: blur(34px);
        border-radius: 30px;
        padding: 26px 22px;
    }
    &__loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(18, 18, 18, .9);
        z-index: 9999;
        flex-direction: column;
        img{
            width: 120px;
        }
        > div{
            color: #fff;
            opacity: .7;
            margin-top: 14px;
        }
    }
    &__modal{
        .modal-content {
            border: 1px solid rgb(5 65 181 / 15%);
            color: #fff;
            background: radial-gradient(53.19% 53.19% at 50% 100%, rgba(110, 196, 255, 0.1) 0%, rgba(110, 196, 255, 0) 100%), rgba(12, 17, 26, 0.4);
            backdrop-filter: blur(34px);
            border-radius: 30px;
        }
        .modal-header{
            border-bottom: 0;
        }
    }
    @media (max-width: 991px) {
        .ethsplitter__line-tabs{
            .nav-item{
                margin: 0 16px;
            }
        }
        &__commonBanner{
            padding: 62px 0;
        }
    }
} //ethsplitter


// Alert notification
.alert {
    border: 1px solid rgba(255, 255, 255, 0.06);
    color: #fff;
    border-radius: 5px;
    backdrop-filter: blur(50px);
    font-size: 14px;
    padding-left: 54px;
    position: relative;
    background-position: 16px center !important;
    background-repeat: no-repeat !important;
    padding-right: 22px;
    &:before{
        content: "";
        position: absolute;
        left: 5px;
        width: calc(100% - 6px);
        top: 100%;
        height: 1px;
        opacity: .6;
       
    }
}
.alert-success{
    background: radial-gradient(42.08% 68.89% at 50% 100%, rgba(93, 227, 163, 0.048) 0%, rgba(93, 227, 163, 0) 100%), rgba(12, 17, 26, 0.4);
    background-image: url(../images/alert-success.png);
    &:before{
        background-color: #4BFCA7;
    }
}
.alert-danger{
    background: radial-gradient(42.08% 68.89% at 50% 100%, rgba(255, 51, 51, 0.048) 0%, rgba(255, 51, 51, 0) 100%), rgba(12, 17, 26, 0.4);
    background-image: url(../images/alert-danger.png);

    &:before{
        background-color: #FF3333;
    }
}
.alert-warning{
    background: radial-gradient(42.08% 68.89% at 50% 100%, rgba(255, 223, 57, 0.08) 0%, rgba(255, 223, 57, 0) 100%), rgba(12, 17, 26, 0.4);
    background-image: url(../images/alert-warning.png);
    &:before{
        background-color: #FFDF39;
    }
}
//circle icons
.circle__icon{
    width: 32px;
    height: 32px;
    transition: all ease .4s;
    border: 1px solid;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 6px;
    img{
        width: 14px;
    }
    &.icon-primary{
        background-color: rgba($primary-color, .14);
        border-color: rgba($primary-color, .14);
        &:hover{
            background-color: transparent;
            border-color: $primary-color;
        }
    }
    &.icon-danger{
        background-color: rgba($theme-danger, .14);
        border-color: rgba($theme-danger, .14);
        &:hover{
            background-color: transparent;
            border-color: $theme-danger;
        }
    }
}

@media (max-width: 991px) {
    .common__heading{
        margin-bottom: 24px;
    }
    p{
        line-height: 1.6;
    }
    .burger{
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .t-burger{
        padding-top: 60px;
    }
    .b-burger{
        padding-bottom: 60px;
    }
}


//Footer
.site-footer {
    font-size: 14px;
}
.footer__inner{
    position: relative;
    padding: 18px 0;
    &:before{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        top: 0;
        background: linear-gradient(90deg, rgba(30, 40, 72, 0) 0%, rgba(255, 255, 255, 0.2) 48.47%, rgba(30, 40, 72, 0) 101.08%);
    }
    .copyright, .footer__menu a{
        opacity: .8;
    }
    .footer__menu {
        text-align: right;
        a{
            text-decoration: none;
        }
    }
}
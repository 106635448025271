body{
    background-color: $base-color;
    color: $base-font-color;
}

//font color
.themeClr, .color-primary{
    color: $primary-color;
}
.color-muted{
    color: $muted-color;
}
//header
.site-header{
    background: rgba(18, 18, 18, 0.2);
}
.primary__menu{
    .nav-item > .nav-link{
        color: #fff;
        &.active{
            color: $primary-color;
            &:after{
                background-color: $primary-color;
            }
        }
    }
}
//header End

// Button
.btn-primary{
    background-color: $primary-color;
    color: #fff;
    &:hover, &:focus, &:active{
        background-color: darken($primary-color, 10%);
        border-color: darken($primary-color, 10%);
        color: #fff;
    }
}
.btn-secondary{
    background-color: $secondary-color;
    border-color: $secondary-color;
    &:hover, &:focus, &:active{
        background-color: darken($secondary-color, 5%);
        border-color: darken($secondary-color, 5%);    
        color: #fff;
    }
    &.disabled, &:disabled {
        color: #fff;
        background-color: #383a3c;
        border-color: #383a3c;
    }
}
.btn-outline{
    background-color: transparent;
    color: #fff;
    border-color: $primary-color;
    &:hover, &:focus, &:active{
        border-color: darken($primary-color, 5%);    
        color: #fff;
    }
    &.disabled, &:disabled {
       opacity: 0.4;
    }
}

// Button End 

.our__values .card{
    background: linear-gradient(94.84deg, rgba(26, 137, 224, 0.08) 0%, rgba(128, 199, 255, 0.02) 95.3%);    
}
.copyright, .footer__menu a{
    color: #fff;
}
//Left Form pages
.siteFormWrapr {
    form .form-control:focus {
        background: transparent;
        border: 1px solid lighten($secondary-color, 20%);
    }
}

//Home 
.float__shadow__icon {
    border-color: rgba($primary-color, $alpha: .5);
    &:before{
        background: $primary-color;
    }
    &:after {
     border-color: $primary-color;
    }
}

//Tooltip
.tooltip.bs-tooltip-end .tooltip-arrow::before{
    border-right-color: rgba($primary-color, 0.5);
}
.tooltip.bs-tooltip-bottom .tooltip-arrow::before{
    border-bottom-color: rgba($primary-color, 0.5);
}

.tooltip-inner{
    background-color: rgba($primary-color, 0.5);
}

// Create-contract-page
.custom__select {
    .css-tj5bde-Svg{
        fill: $primary-color;
    }
}
#react-select-3-listbox > div > div{
    &:not(.css-yt9ioa-option), &:focus, &:hover{
        background-color: $primary-color !important;
    }
}
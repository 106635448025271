// Variables Start 
$primary-color: #1A89E0;
$secondary-color: #222222;
$base-font: 'Montserrat', sans-serif;
$base-color: #090909;
$base-font-color: #fff;
$muted-color: #818DA7;
//muted 2  #adadad;
$base-font-size: 16px;
$theme-danger: #f85353;
// Variables End
//header
.navbar-brand {
  font-weight: 500;
  span {
    color: $primary-color;
  }
}

.site-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(20px);
  padding: 0;
  .container {
    display: block;
  }
  .profile__dropdown {
    #profileDropdown {
      width: 46px;
      height: 46px;
      background: linear-gradient(180deg, #b5e5ff 0%, #6fccfd 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-weight: 600;
      cursor: pointer;
      color: $primary-color;
      filter: drop-shadow(0px 8px 10px rgba($primary-color, 0.4) );
    }
    .dropdown-menu {
      font-size: 0.9em;
      border: 1px solid #0541b53d;
      background: #14181f;
      backdrop-filter: blur(20px);
      border-radius: 14px;
      padding: 0;
      overflow: hidden;
      left: 50%;
      transform: translate(-80%, 8px);
      .dropdown-item {
        color: #fff;
        padding: 12px 20px;
        img{
          width: 16px;
          margin-right: 8px;
        }
        &:hover {
          background-color: darken($primary-color, 12%);
          img{
            filter: brightness(100);
          }
        }
        &:focus,
        &:active {
          background-color: $primary-color;
        }
      }
    }
  }
}
.primary__menu {
  .nav-item {
    > .nav-link {
      padding: 28px 24px;
      position: relative;
      font-weight: 500;
      cursor: pointer;
      &.active {
        &:before{
          transform: scale3d(1,1,1);
        }
      }
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient( 0deg, $primary-color 0%, rgba(26, 137, 224, 0.08) 0.01%, rgba(26, 137, 224, 0) 100%);
        transform: scale3d(1,0,1);
        transform-origin: bottom;
        transition: transform ease .45s;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        border-radius: 20px;
      }
    }
  } 
}
@media (min-width: 992px) {
  .primary__menu {
    margin-right: 10px;
  }
  .navbar-brand {
    img{
      width: 140px;
    }
  }
}
@media (max-width: 991px) {
  .site-header {
    padding: 12px 0;
    border-bottom: 0;
    .profile__dropdown #profileDropdown {
      width: 38px;
      height: 38px;
      font-size: 14px;
    }
    .btn-primary{
      padding: 6px 24px; border-radius: 10px; font-weight: 400;
    }
    &:before{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      bottom: 0;
      background: linear-gradient(90deg, rgba(30, 40, 72, 0) 0%, rgba(255, 255, 255, 0.2) 48.47%, rgba(30, 40, 72, 0) 101.08%);
    }
  }
  .primary__menu {
    .nav-item > .nav-link {
      padding: 16px 14px;
      font-size: 14px;
    }
  }
  .navbar-toggler {
    border: none;
    padding: 0;
    box-shadow: none !important;
    outline: none !important;
    span {
      display: block;
      width: 22px;
      height: 2px;
      margin-bottom: 5px;
      position: relative;
      background: #fff;
      z-index: 1;
      transform-origin: 4px 0px;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
      &:nth-last-child(2) {
        transform-origin: 0% 100%;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:not(.collapsed) {
      span {
        opacity: 1;
        transform: rotate(-45deg) translate(2px, 0px);
        background: #cdcdcd;
        &:nth-last-child(2) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }
        &:nth-last-child(3) {
          transform: rotate(45deg) translate(4px, 0px);
        }
      }
    }
  }
  .navbar-brand {
    margin-right: 0;
    margin-left: 16px;
  }
  .profileMenu {
    text-align: right;
  }
  
}
